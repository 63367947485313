import { useStaticQuery, graphql } from "gatsby";

export const sliderSectionQuery = () => {
  const data = useStaticQuery(
    graphql`
      query SliderSectionData {
        section: allWordPressAcfSliderTextSection {
            nodes {
                id
                background {
                    localFile {
                        childImageSharp {
                        fluid(maxWidth: 1920, quality: 95) {
                                srcSet
                            }
                        }
                    }
                }
                slider {
                    header
                    text
                }
            }
        }
      }
    `
  );
  return data.section;
};
