import { useStaticQuery, graphql } from "gatsby";

export const BookmarkSectionQuery = () => {
  const data = useStaticQuery(
    graphql`
      query BookmarkSectionData {
        section: allWordPressAcfBookmarkSection {
            nodes {
                id
                bookmark_content {
                    header
                    text
                }
            }
        }
      }
    `
  );
  return data.section;
};
