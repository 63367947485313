import React from "react";
import Slider from "./sections-components/Slider/Slider";
import Contact from "./sections-components/Contact/Contact";
import ImageHeaderText from "./sections-components/ImageHeaderText/ImageHeaderText";
import Bookmark from "./sections-components/Bookmark/Bookmark";
import Products from "./sections-components/Products/Products";
import Map from "./sections-components/Map/Map";
const Sections = props => {
  if (!props.data) return null;

  return props.data.map(element => {
    let section = element.__typename;

    switch (section) {
      case "WordPressAcf_slider_text_section":
        return <Slider sectionId={element.id} />;
      case "WordPressAcf_contact_section":
        return <Contact sectionId={element.id} />;
      case "WordPressAcf_image_header_editor_section":
        return <ImageHeaderText sectionId={element.id} />;
      case "WordPressAcf_bookmark_section":
        return <Bookmark sectionId={element.id} />;
      case "WordPressAcf_products_section":
        return <Products sectionId={element.id} />;
      case "WordPressAcf_map_section":
        return <Map sectionId={element.id} />;
      default:
        return null;
    }
  });
};

export default Sections;
