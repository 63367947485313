import { useStaticQuery, graphql } from "gatsby";

export const ImageHeaderTextSectionQuery = () => {
  const data = useStaticQuery(
    graphql`
      query ImageHeaderTextSectionData {
        section: allWordPressAcfImageHeaderEditorSection {
            nodes {
            id
            header
            image {
                localFile {
                childImageSharp {
                    fluid(maxWidth: 540, quality: 95 ) {
                    srcSet
                    }
                }
                }
            }
            text
            }
        }
      }
    `
  );
  return data.section;
};
