import React from "react";
import { ContactSectionQuery } from "./Query";
import Instagram from "../../../../svg/Instagram.jsx";
import Facebook from "../../../../svg/Facebook.jsx";

const Contact = props => {
    const { nodes } = ContactSectionQuery();
    const sectionId = props.sectionId;
    let data = [];

    nodes.map(el => { return el.id === sectionId ? (data = [...data, el]) : null; });
    
    let item = data[0];

    return (
        <section className="contact-section" id="kontakt">
            <h2>{ item.header }</h2>
            <div className="editor" dangerouslySetInnerHTML={ { __html: item.address_contact } } />
            <div className="socials">
                <a href={ item.facebook_url } className="fb">
                    <Facebook />
                </a>
                <a href={ item.instagram_url } className="insta">
                    <Instagram />
                </a>
                <span className="text">Obserwuj nas na social media</span>
            </div>
        </section>
    )
}

export default Contact;