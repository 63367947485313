import React from 'react';

 const Pick = ( props )  => (
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340.8 485.6">
  <g>
    <g>
      <g>
        <path class="st0" d="M170.4,0C76.3,0,0,76.3,0,170.4c0,7.2,0.6,14.2,1.5,21.2C16,324.7,159.4,478.2,159.4,478.2
          c2.6,2.9,5,4.6,7.4,5.9l0.1,0.1l4.8,1.5l4.8-1.5l0.1-0.1c2.4-1.3,4.8-3.1,7.4-5.9c0,0,141.3-153.7,155.5-286.8
          c0.9-6.9,1.4-13.8,1.4-20.9C340.8,76.3,264.5,0,170.4,0z M170.4,280C110,280,60.8,230.9,60.8,170.4S110,60.8,170.4,60.8
          S280,110,280,170.4S230.9,280,170.4,280z"/>
      </g>
    </g>
  </g>
  </svg>

)

export default Pick;