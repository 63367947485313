import { useStaticQuery, graphql } from "gatsby";

export const MapSectionQuery = () => {
  const data = useStaticQuery(
    graphql`
      query MapSectionData {
        section:allWordPressAcfMapSection {
          nodes {
            id
            shops {
              acf {
                shop_name
                city
                post_code_shop
                address_shop
                lat
                long
              }
            }
          }
        }
      }
    `
  );
  return data.section;
};
