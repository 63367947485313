import React from "react";
import { ImageHeaderTextSectionQuery } from "./Query";
import Img from 'gatsby-image';

const ImageHeaderText = props => {
    const { nodes } = ImageHeaderTextSectionQuery();
    const sectionId = props.sectionId;
    let data = [];

    nodes.map(el => { return el.id === sectionId ? (data = [...data, el]) : null; });
    
    let item = data[0];
    return (
        <section className="image-header-text-section" id="o-produkcie">
            <div className="image-box">
                <Img fluid={ item.image.localFile.childImageSharp.fluid } />
                <h2>{ item.header }</h2>
                <div className="text-box" dangerouslySetInnerHTML={ { __html: item.text } } />
            </div>
        </section>
    )
}

export default ImageHeaderText;