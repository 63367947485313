import { useStaticQuery, graphql } from "gatsby";

export const ProductsSectionQuery = () => {
  const data = useStaticQuery(
    graphql`
      query ProductsSectionData {
        section: allWordPressAcfProductsSection {
          nodes {
            id
            header
            products_list {
              acf {
                icons {
                  icon {
                    alt_text
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 100, quality: 95) {
                          srcSet
                        }
                      }
                    }
                  }
                }
                category {
                  name
                  slug
                }
                editor
              }
            }
          }
        }
      }
    `
  );
  return data.section;
};
