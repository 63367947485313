import React from 'react';

 const SliderArrow = ( props )  => (
    <svg xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 555.4 394.3">
        <g>
            <g>
                <path class="st0" d="M533,176.5H22.4C10,176.5,0,185.7,0,197.2c0,11.4,10,20.7,22.4,20.7H533c12.4,0,22.4-9.3,22.4-20.7
                    C555.4,185.7,545.3,176.5,533,176.5z"/>
            </g>
        </g>
        <g>
            <g>
                <path class="st0" d="M49.9,197.2L211.8,35.3c8.1-8.1,8.1-21.2,0-29.2c-8.1-8.1-21.2-8.1-29.2,0L6.1,182.5
                    c-8.1,8.1-8.1,21.2,0,29.2l176.5,176.5c4,4,9.3,6.1,14.6,6.1s10.6-2,14.6-6.1c8.1-8.1,8.1-21.2,0-29.2L49.9,197.2z"/>
            </g>
        </g>
    </svg>

)

export default SliderArrow;
