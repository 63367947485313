import React from "react";
import { sliderSectionQuery } from "./Query";
import Img from 'gatsby-image';
import SliderSlick from "react-slick";
import ArrowSlider from './../../../../svg/SliderArrow';

const Slider = props => {
    const { nodes } = sliderSectionQuery();
    const sectionId = props.sectionId;
    let data = [];
    nodes.map(el => { return el.id === sectionId ? (data = [...data, el]) : null; });

    function NextArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div className={className + " nextArrow"}  onClick={onClick} >
              <ArrowSlider />
              <div className="line"></div>
          </div>
        );
      }
      
      function PrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div className={className + " prevArrow"}  onClick={onClick} >
                <ArrowSlider />
                <div className="line"></div>
            </div>
        );
      }

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        arrows: true,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />

      };

    return (
        <>
            { data.map( (el, i ) => (
                <section className="slider-section" id="strona-glowna">
                    <div className="scroll-down">
                        <a href="#o-produkcie" className="circle"></a>
                        <p>Zjedź w dół</p>
                        <div className="line" />
                    </div>
                    <SliderSlick { ...sliderSettings }>
                        { el.slider.map( ( element, i ) => (
                            <div className="item-slide" key={ i }>
                                <div className="text">
                                    <h1> { element.header } </h1>
                                    <span dangerouslySetInnerHTML={ { __html: element.text } } />
                                </div>
                            </div>
                                
                        ) ) } 
                    </SliderSlick>
                    
                    <div className="image">
                        <Img fluid={ el.background.localFile.childImageSharp.fluid } />
                    </div>
                </section>
            ))}
        </>
    )
}

export default Slider;