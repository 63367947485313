import React, { useState } from "react";
import { BookmarkSectionQuery } from "./Query";

const Contact = props => {
    const [ showBookmark, setShowBookmark ] = useState();
    const { nodes } = BookmarkSectionQuery();
    const sectionId = props.sectionId;
    let data = [];

    nodes.map(el => { return el.id === sectionId ? (data = [...data, el]) : null; });

    const bookmark = (index) => {
        if(showBookmark != index) {
            setShowBookmark(index); 
        }else if(showBookmark === index) {
            setShowBookmark('');
        }else{
            setShowBookmark(index);
        }
    }
    return (
        <section className="bookmark-section">
            {data[0].bookmark_content.map((item, index) => (
                <div className="item-box">
                    <div className="actions">
                        <h3>{ item.header }</h3>
                        <button onClick={ () => bookmark(index) }>{ showBookmark === index ? "Zamknij" : "Przeczytaj więcej" }</button>
                    </div>
                    <div className={ showBookmark === index ? "editor open" : "editor" } dangerouslySetInnerHTML={ { __html: item.text } } />
                </div>
            ))}
        </section>
    )
}

export default Contact;