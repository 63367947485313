import { useStaticQuery, graphql } from "gatsby";

export const ContactSectionQuery = () => {
  const data = useStaticQuery(
    graphql`
      query ContactSectionData {
        section: allWordPressAcfContactSection {
            nodes {
                id
                header
                address_contact
                facebook_url
                instagram_url
            }
        }
      }
    `
  );
  return data.section;
};
